/**
 * Created by xinshanwu on 2017/4/27.
 */

/**
 * Created by xinsw on 2016/9/27.
 * 报警的service
 * Functions
 *  - createAlarm  新建一个报警
 *  - updateAlarm 更新一个报警信息
 *  - getAlarms 获取自己的报警列表
 *  - getAlarmsByKey 根据关键字搜索获取自己的报警列表
 */

import * as API from "../utils/http/api";
import { PAGE_SIZE } from "../config";

/**
 * 根据关键字搜索报警列表
 * @param params Object
 * @param pager Object
 * @returns {Promise.<void>}
 */
export async function searchAlarmByKey(
  params,
  pager = { size: PAGE_SIZE, page: 1 }
) {
  try {
    return await API.get("deviceAlert", { ...params, ...pager });
  } catch (error) {
    return error;
  }
}

/**
 * 创建报警对象
 * @param alarm Object
 * @returns {Promise.<*>}
 */
export async function createAlarm(alarm) {
  try {
    return await API.post("alarm", alarm);
  } catch (error) {
    return error;
  }
}

/**
 * 更新一个报警信息
 * @param alarm Object
 * @returns {Promise.<*>}
 */
export async function updateAlarm(alarm) {
  try {
    return await API.post("alarm/update", alarm);
  } catch (error) {
    return error;
  }
}

/**
 * 删除报警
 * @param alarmId String 报警id
 * @returns {Promise.<void>}
 */
export async function removeAlarm(alarmId) {
  try {
    return await API.del("deviceAlert/" + alarmId);
  } catch (error) {
    return error;
  }
}

/**
 * 根据Key搜索报警定义
 */
export async function getAlertConfigByKey(
  params,
  pager = { size: PAGE_SIZE, page: 1 }
) {
  try {
    return await API.get("alertDefine", { ...params, ...pager });
  } catch (error) {
    return error;
  }
}

/**
 * 创建一个报警定义
 */
export async function createAlertConfig(params) {
  try {
    return await API.post("alertDefine", params);
  } catch (error) {
    return error;
  }
}

/**
 * 更新一个报警定义
 */
export async function updateAlertConfig(params) {
  try {
    return await API.post("alertDefine/update", params);
  } catch (error) {
    return error;
  }
}

/**
 * 删除一个报警定义
 */
export async function removeAlertConfig(ids) {
  try {
    return await API.del("alertDefine/" + ids);
  } catch (error) {
    return error;
  }
}

/**
 * 获取自己的报警列表
 * @param pager Object 分页对象
 * @returns {Promise.<*>}
 */
export async function getAllAlarms(pager = { size: PAGE_SIZE, page: 1 }) {
  try {
    return await API.del("alarm", { ...pager, sort: "createTime,desc" });
  } catch (error) {
    return error;
  }
}

/**
 *
 * @param deviceId 设备ID
 * @param pager 分页对象
 * @returns {Promise.<void>}
 */
export async function getAlarmsByDeviceId(
  deviceId,
  pager = { size: PAGE_SIZE, page: 1 }
) {
  try {
    return await API.get("alarm/device/" + deviceId, {
      ...pager,
      sort: "createTime,desc",
    });
  } catch (error) {
    return error;
  }
}

/**
 * 获取当前正在报警的列表
 * @param pager
 * @returns {Promise.<*>}
 */
export async function getCurrentAlarms(
  pager = { size: PAGE_SIZE, page: 1, sort: "createTime,desc" }
) {
  try {
    return await API.get("alarm/currentAlarm", {
      ...pager,
      sort: "createTime,desc",
    });
  } catch (error) {
    return error;
  }
}

/**
 * 根据设备ID获取当前设备正在报警的列表
 * @param deviceId String
 * @param pager Object
 * @returns {Promise.<void>}
 */
export async function getKeepAlarmsByDevice(
  key,
  pager = { size: PAGE_SIZE, page: 1 }
) {
  try {
    return await API.get("/alarm/byKey", {
      key,
      isFinish: false,
      ...pager,
      sort: "createTime,desc",
    });
  } catch (error) {
    return error;
  }
}

/**
 * 将未读报警设置成已读
 * @param alarmId String
 * @returns {Promise.<void>}
 */
export async function updateAlarmReaded(id) {
  try {
    return await API.post("deviceAlert/update", { id: id, read: true });
  } catch (error) {
    return error;
  }
}

/**
 * 将未读报警设置成已读
 * @param alarmId String
 * @returns {Promise.<void>}
 */
export async function setAlarmReaded(id) {
  try {
    return await API.get("deviceAlert/read/" + id);
  } catch (error) {
    return error;
  }
}
