<style lang="less" scoped>
  @import "../../assets/css/variables";

  .customer-detail {
    // width: 40%;
    position: absolute;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    margin-bottom: 50px;
    top: 15%;
  }
</style>

<template :is="view" transition="fade" transition-mode="out-in">
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/customer/list' }">
        报警中心
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-container clearfix" v-on:keyup.13="getCustomerByName">
      <el-col :span="24" style="padding-bottom: 0px;">
        <el-form :inline="true" :model="filters">
          <el-form-item>
            <el-input
              style="width: 200px"
              v-model="filters.key"
              placeholder="请输入mac地址"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getCustomerByName">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-table
        :data="alarmList"
        v-loading.body="listLoading"
        @selection-change="selsChange"
        class="list-table"
      >
        <el-table-column type="selection" width="35"></el-table-column>
        <el-table-column label="序号" align="center" width="70">
          <template slot-scope="scope">
            <span>{{ scope.$index + pager.size * (pager.page - 1) + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="deviceName"
          min-width="120"
          :show-overflow-tooltip="true"
          label="设备名称"
          sortable
        ></el-table-column>
        <el-table-column
          prop="deviceTypeName"
          min-width="100"
          :show-overflow-tooltip="true"
          label="设备型号"
        ></el-table-column>
        <el-table-column
          prop="mac"
          min-width="100"
          :show-overflow-tooltip="true"
          label="mac地址"
        ></el-table-column>
        <el-table-column
          prop="alertCode"
          min-width="100"
          :show-overflow-tooltip="true"
          label="报警编码"
        ></el-table-column>
        <el-table-column
          prop="alertName"
          label="报警内容"
          :show-overflow-tooltip="true"
          min-width="100"
        ></el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="130"
          label="开始时间"
        >
          <template slot-scope="scope">
            {{ scope.row.createTime | formatTime }}
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          width="130"
          label="结束时间"
        >
          <template slot-scope="scope">
            {{ scope.row.finishTime | formatTime }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button
              :disabled="Boolean(scope.row.isRead)"
              size="small"
              @click="handleToRead(scope.row)"
            >
              {{ scope.row.isRead ? "已读" : "未读" }}
            </el-button>
            <el-button
              :disabled="!editPermission"
              type="danger"
              size="small"
              @click="handleDel(scope.$index, scope.row)"
              class="m-l-xs"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--工具条-->
      <el-col :span="24" class="bottom-tool-container">
        <el-button
          type="danger"
          @click="batchRemove"
          :disabled="!this.sels.length > 0 || !editPermission"
        >
          批量删除
        </el-button>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pager.page"
          :page-sizes="[PAGE_SIZE, PAGE_SIZE * 2, PAGE_SIZE * 3, 100]"
          :page-size="pager.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          class="pull-right"
        ></el-pagination>
      </el-col>
    </div>
    <el-dialog
      :title="isEdit ? '修改客户信息' : '新增客户'"
      size="small"
      :visible.sync="customerDetailVisible"
    >
      <customer-detail
        :isEdit="isEdit"
        :propCustomerInfo="customerInfo"
        v-on:onSubmit="customerDetailSubmit"
        :isRootLevel="true"
        :customerDetailVisible="customerDetailVisible"
      ></customer-detail>
    </el-dialog>
  </div>
</template>

<script>
  import {
    searchAlarmByKey,
    removeAlarm,
    enabledCustomer,
    updateAlarmReaded,
    setAlarmReaded,
  } from "../../services/alarm";

  import { Notification } from "element-ui";
  import { PAGE_SIZE } from "../../config";
  import { mapGetters, mapActions } from "vuex";
  import moment from "moment";
  import ElButton from "../../../node_modules/element-ui/packages/button/src/button";

  export default {
    data() {
      return {
        editPermission: true,
        isEdit: false,
        loaded: false,
        isLargeScreen: window.innerWidth > 1400,
        PAGE_SIZE,
        pager: {
          size: PAGE_SIZE,
          page: 1,
        },
        total: 1,
        choosedRowIndex: -1, //当前选中的列，主要是在删除和修改的时候用
        alarmList: [],
        filters: {
          key: "",
        },
        customerInfo: {
          name: "",
          contact: "",
          contactTel: "",
          isProxy: false,
          proxy: "",
          address: "",
          remarks: "",
          outChain: "",
          storeAddress: "",
        },
        listLoading: false,
        customerDetailVisible: false,
        sels: [], //列表选中列
      };
    },
    components: {
      ElButton,
    },
    computed: {
      ...mapGetters({ user: "userInfo" }),
    },
    filters: {
      formatTime(row) {
        return row ? moment(row).format("YYYY-MM-DD HH:mm:ss") : "";
      },
    },
    methods: {
      /**
       * 判断编辑权限
       */
      checkEditPermission() {
        let permissions = this.user && this.user.permissions;
        console.log(this.user);
        this.editPermission = permissions.some((item) => {
          return item === "Customer:update";
        });
      },
      /**
       * 单条数据的删除
       */
      handleDel: function(index, row) {
        this.$confirm("确认删除该记录吗?", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            //NProgress.start();
            removeAlarm(row.id).then((res) => {
              this.listLoading = false;
              if (res.errorCode === 0) {
                Notification.success({
                  title: "成功",
                  message: "删除成功",
                });
                this.queryAlarms();
              }
            });
          })
          .catch(() => {});
      },
      /**
       * 跳转到编辑
       */
      handleEdit: function(index, row) {
        this.customerDetailVisible = true;
        // row;
        this.isEdit = true;
        this.choosedRowIndex = index;
        const choosedCustomer = Object.assign({}, row);
        this.customerInfo = {
          id: choosedCustomer.id,
          name: choosedCustomer.name,
          contact: choosedCustomer.contact,
          contactTel: choosedCustomer.contactTel,
          isProxy: choosedCustomer.isProxy,
          address: choosedCustomer.address,
          remarks: choosedCustomer.remarks,
          proxy: choosedCustomer.proxy,
          outChain: choosedCustomer.outChain,
          storeAddress: choosedCustomer.storeAddress,
        };
      },

      /**
       *客户修改或者新增成功
       * @param isCreate true 创建  false 修改
       */
      customerDetailSubmit(customerData) {
        if (this.isEdit) {
          this.alarmList.splice(this.choosedRowIndex, 1, customerData);
        } else {
          this.total++;
          //这里的逻辑是倒序的，根据createTime倒序 取最新值。所以默认插入到第一行
          if (this.pager.page == 1) {
            // Math.floor(this.total / this.pager.size)) {
            this.alarmList.unshift(customerData);

            if (this.total % PAGE_SIZE === 1) {
              this.alarmList.pop();
              //              this.pager.page++;
            }
          }
        }
        this.customerDetailVisible = false;
        return false;
      },

      /**
       * checkbox勾选的变动的回调
       */
      selsChange: function(sels) {
        this.sels = sels;
      },

      /**
       * 批量删除
       */
      batchRemove() {
        const ids = this.sels.map((item) => item.id).toString();
        this.$confirm("确认删除选中记录吗？", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            //NProgress.start();
            removeAlarm(ids).then((res) => {
              this.listLoading = false;
              //NProgress.done();
              if (res.errorCode === 0) {
                Notification.success({
                  title: "成功",
                  message: "批量删除成功",
                });
                this.queryAlarms();
              }
            });
          })
          .catch(() => {});
      },
      /**
       * 根据客户名筛选
       */
      getCustomerByName() {
        this.queryAlarms();
      },

      /**
       *  翻页
       */
      handleCurrentChange(page) {
        //        this.pager.page = page;
        //        this.queryAlarms();
        if (this.loaded) {
          this.pager.page = page;
          window.router.replace({ path: "list", query: { page: page } });
          this.queryAlarms();
        }
      },
      /**
       * 设置分页的size
       */
      handleSizeChange(val) {
        this.pager.size = val;
        this.pager.page = 1;
        this.queryAlarms();
      },

      /**
       * 获取所有的报警
       */
      async queryAlarms() {
        this.listLoading = true;
        let searchParam = Object.assign({}, this.filters);
        console.log(searchParam);
        // if (Object.prototype.toString.call(searchParam.isProxy) != '[object Boolean]') {
        //   delete searchParam.isProxy;
        // }
        const responseData = await searchAlarmByKey(searchParam, this.pager);
        if (responseData.errorCode === 0 && responseData.data) {
          this.alarmList = responseData.data.list || [];
          this.total = responseData.data.total;
        }
        this.loaded = true;
        this.listLoading = false;
      },

      /**
       * 标记成已读
       * @param row 当前列的数据对象
       * @returns {Promise.<void>}
       */
      async handleToRead(row) {
        this.$confirm("确认要标记为已读吗?", "提示", {
          type: "warning",
        }).then(() => {
          this.toReaded(row);
        });
      },

      async toReaded(row) {
        let responseData = await setAlarmReaded(row.id);
        console.log(responseData);
        if (responseData.errorCode === 0 && responseData.data.isRead === 1) {
          row.isRead = true;
          Notification.success({
            title: "成功",
            message: "已标记为已读",
          });
          this.queryAlarms();
        }
      },
    },
    async created() {
      if (this.$route.query.page) {
        this.pager.page = +this.$route.query.page;
      }
      this.queryAlarms();
      // this.checkEditPermission();
    },
    watch: {
      customerDetailVisible(newVal, oldVal) {
        if (newVal === false) {
          this.isEdit = false;
        }
      },
    },
  };
</script>
